import axios from 'axios'

export function getUsuarioByEmail (email) {
  return axios.get('/usuarios?email=' + btoa(email))
  .then(response => {
    return response.data
  })
}

export function updateUsuario (id, usuario) {
  return axios.put('/usuarios/' + id, usuario)
  .then(response => {
      return response.data
    })
}

export default {
  getUsuarioByEmail,
  updateUsuario,
}
